.important, .info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em 0 0;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 1em;
}

.important::before, .info::before {
  font-size: 300%;
}

.important {
  background-color: #ffaaaa;
  border-color: red;
}

.important::before {
  content: "⚠️";
  padding: 0.3em;
}

.important p, .info p {
  margin: 15px 0;
}

.info {
  background-color: #eeeeff;
  border-color: #aaaaff;
}

.info::before {
  content: "i";
  font-family: "serif";
  color: #eeeeff;
  background-color: #aaaaff;
  height: 1em;
  flex: 0 0 1em;
  border-radius: 0.5em;
  text-align: center;
  line-height: 1.1em;
  display: inline-block;
  margin: 0.3em;
}

@media only screen and (max-width: 37em) {
  .important, .info {
    flex-direction: column;
    padding: 0 1em;
  }

  .important p, .info p {
    margin: 0 0 15px 0;
  }

  .important::before, .info::before {
    font-size: 200%;
  }

  .info::before {
    width: 1em;
  }
}

// not in love with italics and light grey for blockquotes from parent theme
blockquote {
  color: inherit;
  font-style: inherit;
  font: inherit;
  letter-spacing: inherit;
}

// not loving the slight blue tint on code samples either
pre, code, pre.highlight, div.highlight pre.highlight {
  background-color: #fafafa;
}

// centre images, I guess?
.image {
  text-align: center;
}

// make quotes a bit more visually significant
blockquote {
  background-color: #eee;
  border-left: 8px solid #ccc;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

// A multi-line code block is a useful thing to have
pre.multiline {
  word-wrap: break-word;
  white-space: pre-wrap;
}

// footnotes should be separated from main content

div.footnotes {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #999999;
  padding-top: 1em;
}

// JWT blog post specific

p.jwt-token, pre.qrcode {
    word-wrap: break-word;
    font-family: monospace, monospace;
    white-space: pre-wrap;
}

pre.jwt-token {
  word-wrap: break-word;
  white-space: pre-wrap;
}

pre.jwt-token-decoded {
  word-wrap: break-word;
  white-space: normal;
}

.jwt-header {
  color: #DC267F;
}

.jwt-payload {
  color: #648FFF;
}

.jwt-signature {
  color: #FE6100;
}

pre.output {
  white-space: pre-wrap;
  .failed {
    color: #ff0000;
  }
  .succeeded {
    color: #22bb22;
  }
}

// Ukraine banner

.ukraine {
  background-color: #0057b7;
  color: #ffffff;
  padding: 0.2em 0 0.3em;
  text-align: center;

  a {
    color: #ffd700;
    text-decoration: underline;
  }
}

.site-header {
  border-top: 0;
}
